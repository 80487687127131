import type { Next } from 'koa'
import newrelic from 'newrelic'
import { v4 as uuidv4 } from 'uuid'

import { isLocal } from '../../shared/utils/env-checks'
import type { CustomKoaContext } from '../server'

export const gbTargetCookies = {
  EXPERIMENTATION_COOKIE_NAME: 'features-user-id',
  MASTER_ID: 'master_id',
  IDENTITY_COOKIE: 'identity.cdn.logged_in',
  WM_COOKIE_NAME: 'Wm',
} as const

const COOKIE_DAYS = 180

const cookieExpiryDate = () => {
  const d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * COOKIE_DAYS)
  return d
}

export const addExperimentHeaders = async (ctx: CustomKoaContext, next: Next) => {
  if (ctx.request.url.startsWith('/static')) {
    return await next()
  }

  try {
    const clientCookieKey = gbTargetCookies.EXPERIMENTATION_COOKIE_NAME
    let clientCookie = ctx.cookies.get(clientCookieKey)

    if (clientCookie === undefined) {
      clientCookie = uuidv4()

      // We also need to include the cookie for the in-flight request
      if (ctx.header === undefined) {
        ctx.header = {}
      }

      const cookieString = `${clientCookieKey}=${clientCookie};`

      ctx.header.cookie =
        ctx.header.cookie === undefined ? cookieString : `${ctx.header.cookie};${cookieString}`
    }

    newrelic && newrelic.addCustomAttribute('experimentationUser', clientCookie)

    const cookieDomain = isLocal() ? 'localhost' : 'which.co.uk'

    // This adds the set-cookie response header - always do this (so we bump up the expiry)
    ctx.cookies.set(clientCookieKey, clientCookie, {
      expires: cookieExpiryDate(),
      httpOnly: false,
      domain: cookieDomain,
      path: '/',
    })
  } catch (error) {
    console.error('Error in addExperimentHeaders:', error)
  }

  await next()
}
