import React, { useEffect, useState } from 'react'

import { loadScript, removeScriptTag } from '../../../../shared'
import { isValidUrl } from '../../utils/isValidUrl'

type Props = {
  provider: string
  sortBy?: string
  packageType?: string
  numberOfDeals?: string
}

export const BroadbandDealsWidget: React.FC<Props> = ({
  provider,
  sortBy = 'MONTHLY_PRICE',
  packageType = 'BROADBAND_ONLY',
  numberOfDeals = '3',
}) => {
  const { BROADBAND_DEALS_WIDGET_SCRIPT = '' } = process.env
  const [hasMounted, setHasMounted] = useState<boolean>(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (isValidUrl(BROADBAND_DEALS_WIDGET_SCRIPT) && hasMounted) {
      loadScript({
        src: BROADBAND_DEALS_WIDGET_SCRIPT,
      })
    }

    return () => {
      removeScriptTag(BROADBAND_DEALS_WIDGET_SCRIPT)
    }
  }, [BROADBAND_DEALS_WIDGET_SCRIPT, hasMounted])

  if (!hasMounted) {
    return null
  }

  return (
    <div
      data-stickee-widget-id="which-480"
      data-type="wordy"
      data-per-page={numberOfDeals}
      data-fixed-filters={`{"suppliers": [${provider}], "package_types": "${packageType}"}`}
      data-sort={sortBy}
      data-testid="broadband-deals-widget"
    />
  )
}
