import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import type { ApolloError } from '@apollo/client'

import { useRegex } from '../../hooks/useRegex'
import { getErrorStatusCode } from '../../utils/get-error-status-code'
import { Status } from '../StatusContext'
import styles from './GenericError.module.scss'

export const GenericError: FunctionComponent<Props> = ({ error }) => {
  const { message } = error
  const errorCode = getErrorStatusCode(error)
  const errorMessage =
    errorCode === '404'
      ? 'The page you’re looking for isn’t here.'
      : "There's a problem with our website."
  const { isCRurl } = useRegex()

  return (
    <Status code={errorCode}>
      <div className={styles.wrapper} data-testid={isCRurl() ? 'consumer-rights-error' : 'error'}>
        <div className={styles.container}>
          <Typography className={styles.heading} tag="h1" textStyle="sb-text-heading-large">
            Oops!
          </Typography>
          <Typography
            className={styles.code}
            data-error={message}
            textStyle="sb-text-interface-body-small-regular"
          >
            {errorCode + ' error'}
          </Typography>
          <Typography className={styles.text} textStyle="sb-text-body-default-regular">
            {errorMessage}
          </Typography>
        </div>
        <picture>
          <source srcSet={imageWEBP} type="image/webp" />
          <source srcSet={imagePNG} type="image/png" />
          <img
            className={styles.image}
            alt="Toaster with burnt toast."
            src={imagePNG}
            loading="lazy"
          />
        </picture>
      </div>
    </Status>
  )
}

///////// IMPLEMENTATION /////////

const imageWEBP = 'https://media.product.which.co.uk/prod/images/original/97d834d3b6ff-toast.webp'
const imagePNG = 'https://media.product.which.co.uk/prod/images/original/a3229443600b-toast.png'

type Props = {
  error: ApolloError
}
